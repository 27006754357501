<template>
	<div>
		<AgentItem
			:subscription="subscription"
			:agent="subscription.agent"
			v-if="subscription.agent"
			@update="update"
		/>
		<FreeSlot
			:subscription="subscription"
			@showAssignSubscriptionModal="showAssignSubscriptionModal"
			v-else
		/>
	</div>
</template>

<script setup>
import AgentItem from '@/modules/broker/components/AgentItem.vue'
import FreeSlot from '@/modules/broker/components/FreeSlot.vue'

const props = defineProps({
	subscription: Object
})

const emit = defineEmits(['update', 'showAssignSubscriptionModal'])

function update() {
	emit('update')
}

function showAssignSubscriptionModal(subscription) {
	emit('showAssignSubscriptionModal', subscription)
}
</script>

<style lang="scss"></style>
