<template>
	<section class="company-office">
		<div class="container">
			<div class="company-office__wrapper">
<!--				<Breadcrumb :pages="pages" />-->
				<div class="company-office__body">
					<div class="company-office__header">
						<h1 class="company-office__title">
							{{ $t('web.agency_profile_title') }}
						</h1>
					</div>
					<div class="company-office__main">
						<div class="input-wrapper">
							<label> {{ $t('web.agency_name') }}*</label>
							<or-input
								class="main-type"
								v-model.trim="agency.name"
								:placeholder="$t('web.name')"
								@input="clearError('name')"
								:error="errors.name"
							/>
						</div>

						<AddPhoto
							class="company-office__upload"
							:label="`${$t('web.add_logo')}*`"
							:default-image="agency.logo"
							@upload="updatePhoto"
						/>
						<div class="input-wrapper">
							<label>{{ $t('web.website') }}*</label>
							<or-input
								class="main-type"
								v-model.trim="agency.website"
								placeholder="https://"
								@input="clearError('website')"
								:error="errors.website"
							/>
						</div>
						<or-button @click="submit" kind="secondary" :height="48">
							{{ $t('web.save') }}
						</or-button>
					</div>
					<div class="company-office__agents">
						<div class="company-office__agents-item">
							<p :class="{ active: activeList }" @click="listToggle">
								{{ $t('web.agent_list') }}
								<or-icon v-if="subscriptions.length" class="icon-arrow" />
							</p>
							<transition>
								<div
									v-if="activeList && subscriptions.length"
									class="company-office__agents-wrapper"
								>
									<div class="company-office__agents-list">
										<agent-item-wrapper
											v-for="subscription in subscriptions"
											:key="subscription.id"
											:subscription="subscription"
											@update="getData"
											@showAssignSubscriptionModal="showAssignSubscriptionModal"
										/>
									</div>
								</div>
							</transition>
							<div class="company-office__agents-btns">
								<or-button
									v-if="subscriptions.length > 0"
									@click="showExtendSlotsModal"
								>
									{{ $t('web.extends_slots') }}
								</or-button>
								<or-link
									:to="{
										name: 'Tariffs',
										params: { lang: $store.state.uiLanguage }
									}"
								>
									{{ $t('web.add_slots') }}
								</or-link>
							</div>
							<!--							<or-button class="w-340"> Add Slots </or-button>-->
						</div>
						<div
							v-if="unassignedBrokers?.length"
							class="company-office__agents-item"
						>
							<p
								:class="{ active: activeListArchived }"
								@click="listArchivedToggle"
							>
								{{ $t('web.agent_list_archives') }}
								<or-icon class="icon-arrow" />
							</p>
							<transition>
								<div
									v-if="activeListArchived"
									class="company-office__agents-list"
								>
									<agent-item
										v-for="item in unassignedBrokers"
										:key="item.email"
										:agent="item"
										@update="getData"
										@showAssignAgentModal="showAssignAgentModal"
									/>
								</div>
							</transition>
						</div>
					</div>
					<div class="company-office__links">
						<or-button :height="48" @click="showModal">
							{{ $t('web.add_agent') }}
						</or-button>

						<!--						<or-link-->
						<!--							:to="{-->
						<!--								name: 'ChangePassword',-->
						<!--								params: { lang: $store.state.uiLanguage }-->
						<!--							}"-->
						<!--							:height="48"-->
						<!--							class="secondary"-->
						<!--						>-->
						<!--							{{ $t('web.change_password') }}-->
						<!--						</or-link>-->

						<or-link
							:to="{
								name: 'Tariffs',
								params: { lang: $store.state.uiLanguage }
							}"
							:height="48"
							class="secondary"
						>
							{{ $t('web.change_plan') }}
						</or-link>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import AddPhoto from '@/modules/broker/components/AddPhoto.vue'
import AgentItem from '@/modules/broker/components/AgentItem.vue'
import requestConfigs from '@/api/requestBrokerConfigs'
import { createRequestBroker } from '@/api/requestBuilder'
import FreeSlot from '@/modules/broker/components/FreeSlot.vue'
import OrButton from '@/components/global/orButton.vue'
import AgentItemWrapper from '@/modules/broker/components/AgentItemWrapper.vue'
import extendSlotsModal from '@/components/modals/CompanyOffice/ExtendSlotsModal.vue'

export default {
	name: 'BrokerChoice',
	props: {
		pages: {
			type: Object,
			requried: true,
			default: () => ({})
		}
	},
	components: {
		AgentItemWrapper,
		OrButton,
		FreeSlot,
		AgentItem,
		AddPhoto,
		Breadcrumb
	},
	data() {
		return {
			activeList: false,
			errors: {},
			brokers: [],
			subscriptionsRaw: [],
			agency: {
				website: null,
				logo: null,
				name: null
			},
			activeListArchived: false
		}
	},
	async beforeMount() {
		// Добвление полей для обработки ошибок с бэка
		Object.keys(this.agency).forEach((key) => {
			this.errors[key] = { text: '', status: false }
		})

		await createRequestBroker(requestConfigs.GETProfileAgency)
			.then((profile) => {
				const { commit } = this.$store
				if (!profile.status) return
				commit('updateProfile', profile.response)
			})
			.catch(() => {})
		const { profile } = this.$store.state.broker
		if (Object.keys(profile).length) {
			this._handleReponseProfile({
				status: true,
				response: profile
			})
		} else {
			await this.getData()
		}
	},
	mounted() {
		const paymentStatus = this.$route.query.status
		if (paymentStatus) {
			if (paymentStatus === 'success') {
				this.$store.commit('showNotif', {
					type: 'success',
					text: this.$t('web.notification_payment_' + paymentStatus)
				})
			} else {
				this.$store.commit('showNotif', {
					type: 'error',
					text: this.$t('web.notification_payment_' + paymentStatus)
				})
			}
		}
	},
	methods: {
		_handleReponseProfile(response) {
			if (!response.status) {
				return
			}
			this.brokers = [...response.response.brokers]
			this.subscriptionsRaw = [...response.response.subscriptions]

			Object.keys(this.agency).forEach((key) => {
				if (response.response[key]) {
					this.agency[key] = response.response[key]
				}
			})
		},
		showExtendSlotsModal() {
			this.$store.commit('showModal', {
				name: 'ExtendSlotsModal',
				props: {
					subscriptions: this.subscriptions
				}
			})
		},
		showAssignAgentModal(agent) {
			this.$store.commit('showModal', {
				name: 'AssignAgentModal',
				props: {
					agent: agent,
					subscriptions: this.subscriptions.filter(
						(subscription) => !subscription.agent
					),
					callback: this.returnAgentApi
				}
			})
		},
		showAssignSubscriptionModal(subscription) {
			this.$store.commit('showModal', {
				name: 'AssignSubscriptionModal',
				props: {
					agents: this.unassignedBrokers,
					subscription: subscription,
					callback: this.returnAgentApi,
					addAgentModal: this.showModal
				}
			})
		},
		returnAgentApi(id, slug) {
			createRequestBroker(requestConfigs.POSTAssignSubscription, {
				jsonPayload: {
					subscription: id,
					agent: slug
				}
			}).then((response) => {
				if (response.status) {
					this.$store.commit('showNotif', {
						type: 'success',
						text: this.$t('web.notification_success_set_agent_unarchived')
					})

					this.getData()
				}
			})
		},
		async getData() {
			createRequestBroker(requestConfigs.GETProfileAgency).then(
				this._handleReponseProfile
			)
		},
		clearError(type) {
			this.errors[type].status = false
			this.errors[type].text = ''
		},
		updatePhoto(photo) {
			this.agency.logo = photo
		},
		listToggle() {
			if (this.subscriptions.length > 0) {
				this.activeList = !this.activeList
			}
		},
		listArchivedToggle() {
			this.activeListArchived = !this.activeListArchived
		},
		showModal() {
			// if (this.brokers.length > 4) {
			// 	this.$store.commit('showModal', {
			// 		name: 'InfoText',
			// 		props: {
			// 			title: this.$t('web.notification_not_allow_add_agent'),
			// 			button: 'Pricing',
			// 			callback: () => {
			// 				this.$store.commit('hideModal')
			//
			// 				this.$router.push({
			// 					name: 'Tariffs',
			// 					params: { lang: this.$store.state.uiLanguage }
			// 				})
			// 			}
			// 		}
			// 	})
			// } else {
			this.$store.commit('showModal', {
				name: 'AddAgent',
				props: {
					callback: async () => {
						await this.getData()
					}
				}
			})
			// }
		},
		submit() {
			const payload = {}

			Object.entries(this.agency).forEach(([key, value]) => {
				if (value) {
					payload[key] = value
				}
			})

			if (payload.logo?.hash) {
				delete payload.logo
			}
			createRequestBroker(requestConfigs.POSTSetProfileAgency, {
				jsonPayload: {
					...payload
				}
			})
				.then(async (response) => {
					if (response.status) {
						await createRequestBroker(requestConfigs.GETProfileAgency)
							.then((profile) => {
								const { commit } = this.$store
								if (!profile.status) return
								commit('updateProfile', profile.response)
								this._processingResponse(profile)
							})
							.catch(() => {})

						this.$store.commit('showNotif', {
							type: 'success',
							text: this.$t('web.notification_profile_update_success')
						})
					}
				})
				.catch((err) => {
					if (Object.keys(err.errors).length) {
						Object.entries(err.errors).forEach(([key, value]) => {
							if (this.errors[key]) {
								this.errors[key].status = true
								this.errors[key].text = value.join(', ')
							}
						})
					}
				})
		}
	},
	computed: {
		extendSlotsModal() {
			return extendSlotsModal
		},
		subscriptions() {
			return this.subscriptionsRaw
				.map((subscription) => {
					const copy = { ...subscription }
					if (copy.agent !== '') {
						copy.agent = this.brokers.find(
							(broker) => broker.slug === copy.agent
						)
					} else {
						copy.agent = undefined
					}
					return copy
				})
				.sort((a, b) => {
					if (a.agent && !b.agent) {
						return -1
					} else if (!a.agent && b.agent) {
						return 1
					} else {
						return 0
					}
				})
		},
		unassignedBrokers() {
			return this.brokers.filter(
				(broker) =>
					this.subscriptionsRaw.findIndex(
						(subscription) => subscription.agent === broker.slug
					) === -1
			)
		},
		mainBrokers() {
			return this.brokers.filter((itm) => itm.status !== 'archived')
		},
		archivedBrokers() {
			return this.brokers.filter((itm) => itm.status === 'archived')
		}
	}
}
</script>
<style lang="scss">
.company-office {
	margin-bottom: 80px;
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}
	&__body {
		display: flex;
		flex-direction: column;
		gap: 40px;
		max-width: 1100px;
		width: 100%;
	}
	&__header {
		display: flex;
		justify-content: space-between;
		gap: 20px;
		& > button {
			max-width: 160px;
			width: 100%;
		}
	}
	&__title {
		font-size: 28px;
		font-weight: 700;
		line-height: 120%;
		margin: 0;
	}
	&__main {
		display: grid;
		max-width: 720px;
		width: 100%;
		grid-template-columns: 1fr 1fr;
		column-gap: 60px;
		row-gap: 20px;
		& .input-wrapper {
			grid-column: 2 /3;
		}
		& button {
			grid-column: 2 / 3;
		}
	}
	&__upload {
		grid-row: 1 / 3;
		padding-right: 60px;
		position: relative;
		&::after {
			content: '';
			right: 0;
			position: absolute;
			display: block;
			width: 1px;
			height: 100%;
			background: rgba(0, 0, 0, 0.1);
		}
	}
	&__links {
		display: flex;
		align-items: center;
		row-gap: 20px;
		column-gap: 40px;
		width: 100%;
		flex-wrap: wrap;
		max-width: 720px;
		button,
		a {
			flex: 1 1 170px;
		}
	}
	&__agents {
		max-width: 720px;
		width: 100%;
	}
	&__agents-list {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
	&__agents-item {
		padding: 40px 0;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		gap: 20px;
		&:first-child {
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
		> p {
			cursor: pointer;
			margin: 0;
			font-size: 16px;
			font-weight: 700;
			line-height: normal;
			display: flex;
			align-items: center;
			gap: 10px;
			i {
				transition: all 0.3s ease;
			}
			&.active {
				i {
					transform: rotate(0deg);
				}
			}
		}
	}
	&__agents-wrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&__agents-btns {
		display: flex;
		align-items: center;
		width: 100%;
		row-gap: 20px;
		column-gap: 40px;
		flex-wrap: wrap;
		& > * {
			width: 100%;
			flex: 1 1 260px;
			max-width: 350px;
		}
	}
	.v-enter-active,
	.v-leave-active {
		transition: opacity 0.2s ease;
	}

	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}
	@media screen and (max-width: 800px) {
		&__main {
			gap: 20px;
		}
		&__upload {
			padding-right: 0;
			&::after {
				display: none;
			}
		}
		&__links {
			column-gap: 20px;
			margin-top: 0;
		}
	}
	@media screen and (max-width: 479px) {
		&__header {
			& > button.button.main {
				height: 30px !important;
				font-size: 12px;

				width: 105px;
			}
		}
		&__wrapper {
			gap: 20px;
		}
		&__body {
			gap: 20px;
		}
		&__title {
			font-size: 22px;
		}
		&__main {
			grid-template-columns: 1fr;
			& .input-wrapper,
			& button,
			a {
				grid-column: unset;
			}
		}
		&__upload {
			grid-row: unset;
		}
		&__links {
			grid-template-columns: 1fr;
		}
		&__agents-item {
			padding: 24px 0;
		}
	}
}
</style>
