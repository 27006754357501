<template>
	<div class="agent-item">
		<div class="agent-item__left">
			<p class="agent-item__name">{{ agent.name }}</p>
			<div class="agent-item__info-item">
				<p>{{ $t('web.email') }}</p>
				<a :href="`mailto:${agent.email}`" target="_blank">
					{{agent.email }}
				</a>
			</div>
			<div class="agent-item__bottom">
				<div v-if="subscription">
					{{ $t('web.valid_until') }}:
					<span>{{ getDateInFormat(subscription.expires_at, $store.state.uiLanguage) }}</span>
				</div>
				<div>
					{{ $t('web.offers') }}:
					<span>{{ agent.offers_count }}</span>
				</div>
			</div>
		</div>
		<div class="agent-item__right">
			<template v-if="subscription">
				<or-button @click="changeAgent"><Edit /></or-button>
				<or-button @click="detachAgent"><Link /></or-button>
			</template>
			<template v-else>
				<or-button @click="returnAgent"><Return /> </or-button>
				<or-button @click="deleteAgent"><Trash /> </or-button>
			</template>
		</div>
	</div>
</template>

<script>
import requestConfigs from '@/api/requestBrokerConfigs'
import { createRequestBroker } from '@/api/requestBuilder'
import Edit from '@/components/svg/Edit.vue'
import Trash from '@/components/svg/Trash.vue'
import Return from '@/components/svg/Return.vue'
import Link from '@/components/svg/Link.vue'
import {getDateInFormat} from "@/utils/dateTime";

export default {
	name: 'AgentItem',
	components: { Link, Return, Trash, Edit },
	props: {
		subscription: {
			type: Object
		},
    agent: {
      type: Object,
      default: () => ({})
    }
	},
	computed: {
		isArchived() {
			return this.content.status === 'archived'
		}
	},
	methods: {
    getDateInFormat,
		changeAgent() {
			this.$router.push({
				name: 'AgentProfile',
				params: {
					lang: this.$store.state.uiLanguage,
					slug: this.agent.slug
				}
			})
		},
		deleteAgentApi() {
			if (!this.agent.slug) {
				return
			}

      createRequestBroker(requestConfigs.DELDeleteBrokerOfAgency, {
        routerPayload: {
          slug: this.agent.slug
        }
      }).then((response) => {
        if (response.status) {
          this.$store.commit('showNotif', {
            type: 'success',
            text: this.$t('web.notification_success_set_agent_archived')
          })

          this.$emit('update')
        }
      })

			// createRequestBroker(requestConfigs.POSTSetBrokerArchive, {
			// 	routerPayload: {
			// 		slug: this.agent.slug
			// 	}
			// }).then((response) => {
			// 	if (response.status) {
			// 		this.$store.commit('showNotif', {
			// 			type: 'success',
			// 			text: this.$t('web.notification_success_set_agent_archived')
			// 		})
      //
			// 		this.$emit('update')
			// 	}
			// })
		},
		deleteAgent() {
			this.$store.commit('showModal', {
				name: 'DeleteAgent',
				props: {
					callback: this.deleteAgentApi
				}
			})
		},
    returnAgent() {
      this.$emit('showAssignAgentModal', this.agent)
    },
    detachAgent() {
      createRequestBroker(requestConfigs.POSTAssignSubscription, {
        jsonPayload: {
          subscription: this.subscription.id
        }
      }).then((response) => {
        if (response.status) {
          this.$store.commit('showNotif', {
            type: 'success',
            text: this.$t('web.notification_success_set_agent_unarchived')
          })

          this.$emit('update')
        }
      })
    },
		// Модалка для примера
		companyModal() {
			this.$store.commit('showModal', {
				name: 'CompanyModal',
				props: {}
			})
		}
	}
}
</script>

<style lang="scss">
.agent-item {
	border-radius: 4px;
	border: 1px solid var(--opacity-black-10, rgba(0, 0, 0, 0.1));
	background: var(--opacity-black-2, rgba(0, 0, 0, 0.02));
	padding: 20px;
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	&__left {
		display: flex;
		flex-direction: column;
		gap: 15px;
		width: 100%;
	}
	&__name {
		padding: 0;
		margin: 0;
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
	}
	&__info-item {
		& p:first-child {
			margin: 0;
			color: var(--secondary-1, #808080);
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
		}
		& p:last-child {
			font-size: 14px;
			font-weight: 600;
			line-height: normal;
			word-break: break-all;
		}

		a {
			font-weight: 600;
			color: var(--01, #c99d56);
			font-size: 14px;
		}
	}
	&__right {
		display: flex;
		gap: 10px;
		& > button {
			width: 50px;
			height: 50px;
		}
	}
	&__bottom {
		display: flex;
		flex-direction: column;
		gap: 5px;
		& > div {
			font-size: 12px;
			color: var(--Secondary-1, #808080);
			span {
				color: var(--Text-1, #000);
			}
		}
	}
	@media screen and (max-width: 620px) {
		&__right {
			display: flex;
			flex-direction: column;
			gap: 10px;
			& > button.button {
				width: 22px;
				height: 22px;
				color: red;
				background: none;
				span path {
					fill: #000;
				}
				&:hover:not([disabled]) {
					background: none;
					border-color: unset;
				}
			}
		}
	}
	@media screen and (max-width: 479px) {
		padding: 10px;
		&__left {
			gap: 10px;
		}
		&__name {
			font-size: 14px;
		}
		&__info {
			grid-template-columns: 1fr;
			gap: 5px;
		}
		&__info-item {
			& p:first-child {
				font-size: 12px;
				font-weight: 400;
			}
			& p:last-child {
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
}
</style>
