<template>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="20"
		height="18"
		viewBox="0 0 20 18"
		fill="none"
	>
		<path
			d="M18.9739 2.75014C17.6065 1.38184 15.3803 1.38147 14.0114 2.74902C14.0087 2.7517 14.0064 2.75468 14.0038 2.75744L11.5729 5.18867C11.3547 5.40687 11.3548 5.76066 11.573 5.97879C11.682 6.08785 11.825 6.14238 11.968 6.14238C12.111 6.14238 12.254 6.08785 12.3632 5.97871L14.8027 3.53892C14.8043 3.53728 14.8056 3.53541 14.8074 3.53378C15.741 2.60747 17.2537 2.60948 18.1838 3.54018C19.1164 4.47259 19.1167 5.98989 18.1844 6.92252L14.7642 10.3426C14.3125 10.7944 13.7119 11.0432 13.073 11.0432C12.4341 11.0432 11.8334 10.7945 11.3816 10.3426C11.1634 10.1244 10.8096 10.1244 10.5914 10.3426C10.3732 10.5608 10.3732 10.9146 10.5914 11.1328C11.2543 11.7956 12.1355 12.1606 13.0729 12.1606C14.0103 12.1606 14.8915 11.7956 15.5543 11.1328L18.9745 7.71257C20.3425 6.34427 20.3422 4.11799 18.9739 2.75014Z"
			fill="white"
		/>
		<path
			d="M4.44566 6.86721L1.02548 10.2873C-0.341851 11.6556 -0.341851 13.8819 1.02563 15.2503C1.70981 15.9345 2.60848 16.2766 3.50729 16.2766C4.40544 16.2765 5.30388 15.9348 5.98784 15.2513C5.98799 15.2511 5.98821 15.2509 5.98843 15.2507L8.42793 12.8106C8.64606 12.5923 8.64606 12.2386 8.42778 12.0204C8.20951 11.8022 7.85587 11.8022 7.63759 12.0204L5.19809 14.4606L5.19794 14.4608C4.26523 15.3929 2.74816 15.3925 1.8159 14.4602C0.883784 13.5274 0.883784 12.0099 1.81575 11.0773L5.23586 7.65726C6.1685 6.72455 7.68594 6.72447 8.61857 7.65696C8.83678 7.87516 9.19057 7.87516 9.40869 7.65689C9.6269 7.43868 9.62682 7.08489 9.40862 6.86676C8.04025 5.49869 5.81381 5.49884 4.44566 6.86721Z"
			fill="white"
		/>
		<path
			d="M9.26822 2.95783C9.35732 3.17305 9.56539 3.30298 9.78472 3.30298C9.85594 3.30298 9.92835 3.28927 9.99823 3.26036C10.2833 3.14236 10.4188 2.81554 10.3008 2.53044L9.66984 1.00629C9.55191 0.721186 9.22502 0.585749 8.93991 0.703754C8.65481 0.821758 8.5193 1.14858 8.63738 1.43368L9.26822 2.95783Z"
			fill="white"
		/>
		<path
			d="M11.4641 3.26007C11.5341 3.28904 11.6065 3.30275 11.6778 3.30275C11.897 3.30275 12.105 3.1729 12.1942 2.95775L12.8257 1.43391C12.9438 1.1488 12.8085 0.821982 12.5234 0.703829C12.2384 0.58575 11.9116 0.720963 11.7933 1.00614L11.1618 2.52999C11.0437 2.81502 11.1791 3.14191 11.4641 3.26007Z"
			fill="white"
		/>
		<path
			d="M6.70817 3.96768L8.23172 4.59927C8.30168 4.62833 8.37416 4.64204 8.44546 4.64204C8.66463 4.64204 8.87263 4.51219 8.9618 4.29711C9.07995 4.01208 8.94474 3.68519 8.65964 3.56704L7.13609 2.93537C6.85106 2.81707 6.52416 2.9525 6.40601 3.23753C6.28786 3.52263 6.42315 3.84953 6.70817 3.96768Z"
			fill="white"
		/>
		<path
			d="M10.7314 15.0425C10.6133 14.7574 10.2866 14.6219 10.0015 14.7399C9.71637 14.8579 9.58086 15.1847 9.69886 15.4698L10.3296 16.9939C10.4187 17.2091 10.6268 17.3391 10.8461 17.3391C10.9173 17.3391 10.9897 17.3254 11.0596 17.2965C11.3447 17.1785 11.4802 16.8517 11.3622 16.5666L10.7314 15.0425Z"
			fill="white"
		/>
		<path
			d="M8.53553 14.74C8.25036 14.6219 7.92361 14.757 7.80538 15.0421L7.17364 16.5657C7.05541 16.8508 7.19063 17.1776 7.47573 17.2958C7.54576 17.3249 7.61824 17.3386 7.68954 17.3386C7.90871 17.3386 8.11671 17.2088 8.20588 16.9937L8.83762 15.4702C8.95577 15.1851 8.82056 14.8582 8.53553 14.74Z"
			fill="white"
		/>
		<path
			d="M13.2909 14.0328L11.7669 13.4012C11.4818 13.2829 11.155 13.4183 11.0369 13.7033C10.9187 13.9884 11.054 14.3152 11.339 14.4334L12.863 15.0651C12.933 15.0942 13.0055 15.1079 13.0768 15.1079C13.2959 15.1079 13.5039 14.978 13.5931 14.763C13.7113 14.4778 13.576 14.151 13.2909 14.0328Z"
			fill="white"
		/>
	</svg>
</template>
