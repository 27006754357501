<template>
	<div class="free-slot" role="button" @click="assignSubscription">
		<div class="free-slot__left">
			<span>{{ $t('web.free_slot_until') }}</span>
			<p>{{ getDateInFormat(subscription.expires_at, $store.state.uiLanguage) }}</p>
		</div>
		<or-icon type="plus" />
	</div>
</template>

<script setup>
import {getDateInFormat} from "@/utils/dateTime";

const props = defineProps({
  subscription: Object
});

const emit = defineEmits(['showAssignSubscriptionModal'])

function assignSubscription() {
  emit('showAssignSubscriptionModal', props.subscription)
}
</script>

<style lang="scss">
.free-slot {
	padding: 32px;
	border-radius: 4px;
	border: 1px dashed var(--opacity-black-30, rgba(0, 0, 0, 0.3));
	background: var(--Background-1, #fff);
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 7px;
	cursor: pointer;
	transition: all 0.3s ease;
	&__left {
		span {
			color: var(--Secondary-1, #808080);
			text-align: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
		p {
			color: var(--Primary-1, #c99d56);
			text-align: center;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin: 0;
		}
	}
	& > i {
		color: #c99d56;
		font-size: 20px;
	}
	&:hover {
		background: var(--Primary-3, #f6eddd);
	}
	@media screen and (max-width: 479px) {
		padding: 22px;
	}
}
</style>
